import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignOutButton } from "./SignOutButton";

function Navbar() {
  const isAuthenticated = useIsAuthenticated();
  return (
    <div className="navbar">
      <h1 style={{ color: "white" }}>Zscaler TMX Splitter</h1>
      {isAuthenticated && (
        <div>
          <SignOutButton />
        </div>
      )}
    </div>
  );
}

export default Navbar;
