import React from "react";
import { DOMAIN } from "../domain";
import MultipleChoiceInput from "./MultipleChoiceInput";

function Project(props) {
  const [download, setDownload] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const handleClick = () => {
    setDownload((prev) => {
      return !prev;
    });
    fetch(`${DOMAIN}/download/list-files`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: props.project.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setFiles(data.files);
      });
  };

  if (!download) {
    return (
      <div className="project" onClick={handleClick}>
        <div>
          <strong>ID</strong>
          <p>{props.project.id}</p>
        </div>
        <div>
          <strong>Name</strong>
          <p>{props.project.name}</p>
        </div>
        <div>
          <strong>Source language</strong>
          <p>{props.project.sourceLanguage}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className="input">
        <MultipleChoiceInput
          options={files}
          onClick={() => {
            handleClick();
          }}
          id={props.project.id}
        />
        <button
          onClick={async () => {
            try {
              const response = await fetch(`${DOMAIN}/download/all-zip`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: props.project.id,
                }),
              });

              if (response.ok) {
                // If the response is successful, create a blob from the response data
                const blob = await response.blob();

                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element to trigger the download
                const a = document.createElement("a");
                a.href = url;
                a.download = `files-${props.project.id}.zip`;
                document.body.appendChild(a);
                a.click();

                // Clean up the anchor element and URL
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              } else {
                // Handle error responses from the backend
                console.error("Download failed:", response.statusText);
              }
            } catch (error) {
              console.error("Download error:", error);
            }
          }}
          style={{ marginBottom: "20px" }}
        >
          Download all
        </button>
      </div>
    );
  }
}

export default Project;
