import React from "react";

import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import wolfestoneLogo from "../wolfestone-logo.svg";

export const Landing = (props) => {
  return (
    <div className="landing" style={{ height: "100vh" }}>
      <img
        src={wolfestoneLogo}
        alt="logo"
        style={{ width: "40rem", height: "10rem", marginBottom: "100px" }}
      ></img>
      <SignInButton />
    </div>
  );
};
