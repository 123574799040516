import React from "react";
import wolfestoneLogo from "../wolfestone-logo.svg";
import { DOMAIN } from "../domain";
import Project from "./Project";

function Dashboard() {
  const [page, setPage] = React.useState("main");

  function changePage(page) {
    setPage(page);
  }
  return (
    <div>
      <img
        src={wolfestoneLogo}
        alt="logo"
        style={{ width: "40rem", height: "10rem", marginBottom: "100px" }}
      ></img>
      {page === "main" && (
        <Main
          changePage={() => {
            changePage("upload");
          }}
        />
      )}
      {page === "upload" && (
        <Upload
          changePage={() => {
            changePage("main");
          }}
        />
      )}
    </div>
  );
}

const Main = (props) => {
  const [projects, setProjects] = React.useState([]);
  React.useEffect(() => {
    fetch(`${DOMAIN}/db/projects`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProjects(data.reverse());
      });
  }, []);
  return (
    <div className="upload-container">
      <button
        className="nav"
        onClick={() => {
          props.changePage("upload");
        }}
      >
        Split new tmx
      </button>
      {projects.length > 0 &&
        projects.map((project, index) => {
          return <Project key={index} project={project} />;
        })}
      {projects.length === 0 && <div className="spinner">↻</div>}
    </div>
  );
};

const Upload = (props) => {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [emptyFile, setEmptyFile] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emptyName, setEmptyName] = React.useState(false);
  const [projectName, setProjectName] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleName = (e) => {
    setProjectName(e.target.value);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    if (projectName) {
      if (selectedFile) {
        setEmptyFile(false);
        setSuccess("");
        setEmptyName(false);
        formData.append("file", selectedFile);
        formData.append("projectName", projectName);
        try {
          setLoading(true);
          const response = await fetch(`${DOMAIN}/upload`, {
            method: "POST",
            body: formData,
            mode: "cors",
            credentials: "include",
          });

          setLoading(false);
          if (response.status === 201) {
            setSuccess("true");
            const data = await response.json();
            console.log(data);
          }
        } catch (error) {
          console.error("Error uploading file:", error);
          setSuccess("false");
        }
      } else {
        setEmptyFile(true);
      }
    } else {
      setEmptyName(true);
    }
  };

  return (
    <div className="upload-container">
      <button
        className="nav"
        style={{ alignSelf: "flex-start", marginLeft: "40px" }}
        onClick={() => {
          props.changePage("main");
        }}
      >
        Back To Dashboard ➩
      </button>
      <div className="input-block">
        <label style={{ margin: "0" }}>Project Name</label>

        <input
          type="text"
          name="projectName"
          id="projectName"
          style={{
            width: "20rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "20px",
            borderRadius: "18px",
            border: "2px solid #405cf5",
          }}
          onChange={handleName}
        />
      </div>
      <input type="file" accept=".tmx" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Split tmx</button>
      {emptyFile && <p style={{ color: "red" }}>Please upload a tmx file.</p>}
      {success === "true" && (
        <p style={{ color: "green" }}>File split successfully</p>
      )}
      {success === "false" && (
        <p style={{ color: "red" }}>Error splitting file</p>
      )}
      {emptyName && <p style={{ color: "red" }}>Please name your project</p>}
      {loading && <div className="spinner">↻</div>}
    </div>
  );
};

export default Dashboard;
