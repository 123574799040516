import React, { useState } from "react";
import { DOMAIN } from "../domain";

function MultipleChoiceInput({ options, onClick, id }) {
  // Initialize state to keep track of selected options
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Function to toggle the selection of an option
  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      // If the option is already selected, remove it
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      // If the option is not selected, add it
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <div>
      <h3>Split files download</h3>
      <p
        className="toggler"
        style={{
          display: "inline-block",
          position: "relative",
          left: "600px",
          fontSize: "60px",
          margin: "0",
          padding: "0",
        }}
        onClick={handleClick}
      >
        ↑
      </p>
      <ul>
        {options.map((option) => (
          <li key={option}>
            <label className="lab">
              <input
                type="checkbox"
                value={option}
                checked={selectedOptions.includes(option)}
                onChange={() => toggleOption(option)}
              />
              {option}
            </label>
          </li>
        ))}
      </ul>
      <div>
        <strong>Selected Files:</strong>
        <ul className="files">
          {selectedOptions.map((option) => (
            <li key={option}>{option}</li>
          ))}
        </ul>
        {selectedOptions.length > 0 && (
          <button
            style={{ marginBottom: "20px" }}
            onClick={async () => {
              try {
                const response = await fetch(`${DOMAIN}/download/selected`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: id,
                    selected: selectedOptions,
                  }),
                });

                if (response.ok) {
                  // If the response is successful, create a blob from the response data
                  const blob = await response.blob();

                  // Create a URL for the blob
                  const url = window.URL.createObjectURL(blob);

                  // Create an anchor element to trigger the download
                  const a = document.createElement("a");
                  a.href = url;
                  a.download = `files-${id}.zip`;
                  document.body.appendChild(a);
                  a.click();

                  // Clean up the anchor element and URL
                  window.URL.revokeObjectURL(url);
                  document.body.removeChild(a);
                } else {
                  // Handle error responses from the backend
                  console.error("Download failed:", response.statusText);
                }
              } catch (error) {
                console.error("Download error:", error);
              }
            }}
          >
            Download selected
          </button>
        )}
      </div>
    </div>
  );
}

export default MultipleChoiceInput;
